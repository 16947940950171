import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import { useCompareBuiltToLast } from 'content-queries/mx/compare/compare-built-to-last'
import { useModernKitchenCabinetry } from 'content-queries/mx/modern-kitchen-cabinetry'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { SectionContent } from 'mx/components/section-content'
import { StatsAwars } from 'mx/components/section-image'
import { Press } from 'mx/homepage/press'
import { Reviews } from 'mx/homepage/reviews'
import Layout from 'mx/layout'
import { Hero } from 'mx/webinar/hero'

const ModernKitchenCabinetryPage = () => {
  const { data } = useModernKitchenCabinetry()
  const [
    {
      node: {
        sectionModules: [
          hero,
          germanExcellence,
          designFeatures,
          fullyAssembled,
          modernService,
          cleanLines,
        ],
      },
    },
  ] = data
  const { data: builtToLastData } = useCompareBuiltToLast()

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Modern Kitchen Cabinetry',
    })
  }, [])

  return (
    <Layout>
      <AutomaticPopup />
      <Hero data={hero} />
      <Press />
      <SectionContent data={germanExcellence} />
      <StatsAwars content={builtToLastData} />
      <SectionContent data={designFeatures} />
      <SectionContent data={fullyAssembled} />
      <SectionContent data={modernService} />
      <Reviews />
      <SectionContent data={cleanLines} />
    </Layout>
  )
}

export default ModernKitchenCabinetryPage

export const Head = () => {
  const { data } = useModernKitchenCabinetry()
  const [
    {
      node: {
        meta: { image, title },
      },
    },
  ] = data
  return (
    <SEO
      description="We offer high-end, modern European cabinetry directly from the factory in Germany. Free design help for about half the cost of the typical studios."
      path="/modern-kitchen-cabinetry"
      title={title}
      image={image}
    />
  )
}
